<template>
    <div class="search-keywords">
        <StackRouterHeaderBar :leftButtonHandler="updateKeywords" />
        <main>
            <h1 class="page-title" v-html="`# ${category.title}`" />

            <!-- <div class="keyword-desc" v-html="keywordDesc" /> -->
            <div class="input-wrapper" :class="{ 'on-search': shouldShowAutoComplete }">
                <i class="material-icons search-icon">search</i>
                <input
                    placeholder="나만의 항목을 추가해보세요"
                    ref="query"
                    @input="onKeyUp"
                    @focus="showAutoComplete = true"
                    @blur="showAutoComplete = false"
                    readonly
                />
                <i v-show="query.length > 0" class="material-icons close-icon" @click="query = ''">cancel</i>

                <transition name="slide-down">
                    <div v-if="shouldShowAutoComplete" class="auto-complete">
                        <div class="items" @click="insertKeyword(query)">
                            # ‘{{ query }}’ <span class="direct">- 직접 입력</span>
                        </div>
                        <div
                            class="items"
                            :class="isSelected(keyword) ? 'selected' : ''"
                            v-for="keyword in lists"
                            :key="keyword.id"
                            @click="insertKeyword(keyword)"
                        >
                            # {{ keyword.name }}
                        </div>
                    </div>
                </transition>
            </div>
            <div class="keywords">
                <div
                    class="keyword"
                    :class="{ off: keyword.$$active != true }"
                    v-for="keyword in keywords"
                    :key="keyword.id"
                    @click="toggleKeyword(keyword)"
                >
                    {{ keyword.name }}
                </div>
            </div>
        </main>
        <BottomButton :disabled="!buttonEnabled" @click="updateKeywords" :label="'저장'" />
    </div>
</template>

<script>
import commonService from '@/services/common'
import debounce from '@/modules/debounce'

export default {
    name: 'SearchKeywordsPage',
    props: {
        category: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        query: '',
        lists: [],
        keywords: [],
        showAutoComplete: false,
        originKeywords: [],
        buttonEnabled: false,
    }),
    watch: {
        keywords() {},
    },
    computed: {
        keywordDesc() {
            return this.category.description
        },
        shouldShowAutoComplete() {
            return this.query.length > 0 && this.showAutoComplete
        },
    },
    mounted() {
        this.$refs.query.focus()
        setTimeout(() => {
            this.$refs.query.readOnly = false
        }, 50)

        this.keywords = [
            ...this.category.keywords.map(k => ({
                ...k,
                $$active: true,
            })),
        ]

        const keywordIds =
            this.keywords && this.keywords.length > 0
                ? this.keywords.map(k => {
                      return k.id
                  })
                : []

        const defaultKeywords = this.$store.getters.defaultKeywords
        defaultKeywords.map(k => {
            if (!keywordIds.includes(k.id) && this.category.id === k.keyword_category_id) {
                this.keywords.push({ ...k, $$active: false })
            }
        })

        this.originKeywords = this.keywords

        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.$unregisterBackHandler(this.back)
    },
    methods: {
        onKeyUp: debounce(function (e) {
            this.query = e.target.value
            this.search()
        }, 200),
        async search() {
            const data = await commonService.keywords(this.category.id, this.query)
            this.lists = [...data]
        },
        async insertKeyword(keyword) {
            if (!keyword) return

            if (this.keywords.some(e => e.name === keyword.name || e.name === keyword)) {
                this.$toast.error('KEYWORD_DUPLICATE_ERROR')
                this.query = ''
                return
            }

            // this.keywords.push(keyword)

            this.buttonEnabled = true

            if (typeof keyword === 'string') {
                try {
                    //  나갈때 한번에 저장되도록
                    //  할라구 했는데..
                    const res = await commonService.userKeywords.create({
                        name: keyword,
                        keywordCategoryId: this.category.id,
                    })
                    res.keyword = {
                        ...res.keyword,
                        $$active: true,
                    }
                    this.keywords.push(res.keyword)
                    // const newKeyword = {
                    //     name: keyword,
                    //     keywordCategoryId: this.category.id,
                    //     $$active: true,
                    // }

                    // this.keywords.push(newKeyword)
                } catch (e) {
                    this.$toast.error(e)
                }
            } else {
                try {
                    // await commonService.userKeywords.create({
                    //     keywordId: keyword.id,
                    //     keywordCategoryId: this.category.id,
                    // })
                    keyword = {
                        ...keyword,
                        $$active: true,
                    }
                    // this.keywords.push(keyword)
                    this.keywords.push(keyword)
                } catch (e) {
                    this.$toast.error(e)
                }
            }

            this.query = ''
            this.$store.dispatch('loadMe')
        },
        async toggleKeyword(keyword) {
            if (!keyword) return

            keyword.$$active = !keyword.$$active
            this.buttonEnabled = true
        },
        async updateKeywords() {
            const activeKeywords = this.keywords.filter(k => k.$$active === true)
            const inactiveKeywords = this.keywords.filter(k => k.$$active === false)
            if (
                (!activeKeywords || activeKeywords.length === 0) &&
                (!inactiveKeywords || inactiveKeywords.length === 0)
            ) {
                this.$store.dispatch('loadMe')
                this.$stackRouter.pop()
                return
            }

            const activeKeywordIds = activeKeywords.map(k => {
                return k.id
            })
            const inactiveKeywordIds = inactiveKeywords.map(k => {
                return k.id
            })
            const payload = {
                user_id: this.$store.getters.me.id,
                keyword_category_id: this.category.id,
                active_keyword_ids: activeKeywordIds,
                inactive_keyword_ids: inactiveKeywordIds,
            }
            try {
                this.$loading(true)
                await commonService.userKeywords.updateKeywords(payload)
                this.$unregisterBackHandler()
            } catch (e) {
            } finally {
                this.$loading(false)
                this.$store.dispatch('loadMe')
                this.$stackRouter.pop()
            }
        },

        async back() {
            const activeKeywords = this.keywords.filter(k => k.$$active === true)
            const inactiveKeywords = this.keywords.filter(k => k.$$active === false)
            if (
                (!activeKeywords || activeKeywords.length === 0) &&
                (!inactiveKeywords || inactiveKeywords.length === 0)
            ) {
                this.$store.dispatch('loadMe')
                // this.$stackRouter.pop()
                return false
            }

            const activeKeywordIds = activeKeywords.map(k => {
                return k.id
            })
            const inactiveKeywordIds = inactiveKeywords.map(k => {
                return k.id
            })
            const payload = {
                user_id: this.$store.getters.me.id,
                keyword_category_id: this.category.id,
                active_keyword_ids: activeKeywordIds,
                inactive_keyword_ids: inactiveKeywordIds,
            }
            try {
                this.$loading(true)
                await commonService.userKeywords.updateKeywords(payload)
                this.$unregisterBackHandler()
            } catch (e) {
            } finally {
                this.$loading(false)
                this.$store.dispatch('loadMe')
                // this.$stackRouter.pop()
            }
            return false
        },

        isSelected(keyword) {
            return this.keywords.findIndex(k => k.id === keyword.id || k === keyword.name) > -1
        },
    },
}
</script>

<style lang="scss" scoped>
$input-height: 48px;
$input-width: calc(100vw - 32px);

.search-keywords {
    main {
        padding: 0 16px;
    }

    .page-title {
        margin-bottom: 40px;
        font-size: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .keyword-desc {
        margin-bottom: 12px;
        font-size: 12px;
        color: $grey-06;

        @include f-medium;
    }

    .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: $input-height;
        width: $input-width;
        padding: 12px 16px;

        &.on-search {
            border-radius: 12px 12px 0 0;
        }

        .material-icons {
            &.search-icon {
                color: $grey-08;
                font-size: 24px;
                margin-right: 12px;
            }

            &.close-icon {
                color: $grey-06;
                font-size: 16px;
            }
        }

        .auto-complete {
            position: absolute;
            top: calc(#{$input-height} - 4px);
            left: -1px;
            width: $input-width;
            max-height: 480px;
            padding: 16px 20px;
            border-radius: 0 0 12px 12px;
            border: solid 1px $grey-02;
            // border-top: 0;
            background-color: white;
            overflow-y: auto;

            .items {
                font-size: 16px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.2px;
                color: $grey-09;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                &.selected {
                    color: $purple-primary;
                }

                span {
                    color: $grey-05;
                }
            }
        }
    }

    .keywords {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 32px;

        .keyword {
            margin-right: 8px;
            margin-bottom: 8px;
            padding: 0 12px;
            border-radius: 8px;
            height: 32px;
            font-size: 14px;
            border: solid 1px $purple-primary;
            color: $purple-primary;

            @include f-medium;
            @include center;

            &.off {
                color: $grey-05;
                border: solid 1px $grey-03;
                background-color: $grey-01;
            }
        }
    }
}
</style>
